var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row settings" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.requests, function(request) {
                  return _c(
                    "div",
                    {
                      key: request.title,
                      staticClass: "col-xs-12 col-md-3 col-lg-3 setting-item"
                    },
                    [
                      _vm.canAny(request.guard)
                        ? _c(
                            "router-link",
                            {
                              class: request.disabled ? "disabledItem" : "",
                              attrs: {
                                to: !request.disabled
                                  ? {
                                      name: request.route,
                                      params: { op: request.routeParam.opType }
                                    }
                                  : ""
                              }
                            },
                            [
                              _c("div", { staticClass: "card" }, [
                                _c("div", { staticClass: "card-body" }, [
                                  _c("h4", { staticClass: "card-title" }, [
                                    _c("img", {
                                      staticClass: "rounded-0",
                                      attrs: {
                                        src: require("@/assets/images/base/menu/" +
                                          request.icon +
                                          ".png"),
                                        alt: request.title,
                                        title: request.title
                                      }
                                    }),
                                    _c("h4", { staticClass: "card-title" }, [
                                      _vm._v(_vm._s(request.title))
                                    ])
                                  ]),
                                  _c("p", { staticClass: "card-text" })
                                ])
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            ])
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "row d-none" },
        _vm._l(_vm.requests, function(request) {
          return _c(
            "div",
            { key: request.title, staticClass: "col-lg-3 azmt-service-card" },
            [
              _vm.canAny(request.guard)
                ? _c(
                    "router-link",
                    {
                      class: request.disabled ? "disabledItem" : "",
                      attrs: {
                        to: !request.disabled ? { name: request.route } : ""
                      }
                    },
                    [
                      _c(
                        "b-card",
                        { attrs: { "no-body": "" } },
                        [
                          _c(
                            "b-row",
                            {
                              staticClass: "align-items-center",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "8" } },
                                [
                                  _c("b-card-body", {
                                    attrs: { title: request.title }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c("b-card-img", {
                                    staticClass: "rounded-0",
                                    attrs: {
                                      src: require("@/assets/images/base/menu/" +
                                        request.icon +
                                        ".png"),
                                      alt: request.title,
                                      title: request.title
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }